<template>
<div>
    <b-row>

        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-chart-bar fa-md"></i> <span class="h5"> Estadísticas</span>
                </CCardHeader>
                <CCardBody>
                    <span class="h6 text-muted">ESTADÍSTICAS DEL SISTEMA PARA LAS OFERTAS
                    </span>
                    <hr>
                    <b-row class="justify-content-center text-center">
                        <b-col cols="12" class=" my-2 icon-custom">
                            <i style="inline-size: auto" class="fas fa-handshake fa-3x ico"> </i>
                            <br>
                            <span class="lg-numero">{{listaOfertas.length}}</span>
                            <br>
                            <span class="text-muted">Ofertas</span>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>
        <b-col md="6">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-cog"></i><span class="h5"> Gestión de ofertas </span>
                </CCardHeader>
                <CCardBody>
                    <b-row class="text-center justify-content-center">
                        <b-col md="6" class="my-2 ">
                            <b-button block size="md" variant="custom" :to="{name: 'Gestión de oferta master'}">
                                <i class="fas fa-plus-circle fa-3x pb-1" style="inline-size: auto"></i>
                                <br>Nueva oferta
                            </b-button>
                        </b-col>
                    </b-row>
                </CCardBody>
            </CCard>
        </b-col>

        <b-col md="12">
            <CCard>
                <CCardHeader color="dark" text-color="white">
                    <i class="fas fa-list fa-md"></i> <span class="h5"> Ofertas registradas</span>
                </CCardHeader>

                <CCardBody>
                    <div>
                        <b-row>
                            <b-col lg="2">
                                <b-form-group label="Registros por página" class="text-muted">
                                    <b-form-select size="xl" v-model="porPagina" :options="paginaOpciones"></b-form-select>
                                </b-form-group>
                            </b-col>
                            <b-col lg="7">
                            </b-col>
                            <b-col lg="3">
                                <b-form-group label="Busqueda:" class="text-muted">
                                    <b-input-group size="xl">
                                        <b-input-group-prepend is-text>
                                            <i class="fas fa-search  my-0 mx-0"></i>
                                        </b-input-group-prepend>
                                        <b-form-input type="search" v-model="filter" id="filterInput" placeholder="Buscar..."></b-form-input>
                                    </b-input-group>
                                </b-form-group>
                            </b-col>
                            <b-col md="12">
                                <b-table :items="listaOfertas" :fields="campoOfertas" :current-page="currentPage" :per-page="porPagina" :filter="filter" :filter-included-fields="filterOn" bordered hover show-empty mediun responsive outlined @filtered="onFiltered" empty-text="Aún no hay datos en esta sección." empty-filtered-text="No hay datos que coincidan con tu busqueda.">
                                    <template v-slot:cell(index)="data">{{ data.index + 1 }}</template>
                                    <template #cell(total)="param">
                                        <span>S/. {{param.item.total}} </span>
                                    </template>
                                    <template v-slot:cell(cliente)="data">
                                        {{data.value | truncate(35)}}
                                    </template>
                                    <template #cell(fechaFinContrato)="param">
                                        <span class="mr-2">{{param.item.fechaFinContrato}}</span>
                                        <span class="h5" v-if="validarFecha(param)">
                                            <b-badge variant="danger">!</b-badge>
                                        </span>
                                    </template>
                                    <template #cell(acciones)="param">
                                        <b-button class="mr-1 mb-1" size="sm" variant="blue" @click="param.toggleDetails" v-c-tooltip="'Servicios'">
                                            <i :class="`fas fa-eye${param.detailsShowing ? '-slash' : ''} my-0 mx-0`"></i>
                                        </b-button>
                                        <b-button @click="verDetalleOferta(param)" class="mr-1 mb-1" size="sm" variant="dark" v-c-tooltip="'Ver detalles'">
                                            <i class="fas fa-search my-0 mx-0"></i>
                                        </b-button>
                                        <b-button @click="eliminarOferta(param)" class="mr-1 mb-1" size="sm" variant="danger" v-c-tooltip="'Eliminar'">
                                            <i class="fas fa-trash-alt my-0 mx-0"></i>
                                        </b-button>
                                    </template>
                                    <template #row-details="row">
                                        <b-row>
                                            <b-col md="3">
                                                <span class="h6"> Servicios contratados: </span>
                                            </b-col>
                                            <b-col md="9">
                                                {{String(row.item.listaServicios.map(x => x.servicio).join(', '))}}
                                            </b-col>
                                        </b-row>
                                        <b-row class="mt-2">
                                            <b-col md="3">
                                                <span class="h6"> Incluye colaborador: </span>
                                            </b-col>
                                            <b-col md="9">
                                                <span v-if="row.item.conColaborador=='2'">SI</span>
                                                <span v-else-if="row.item.conColaborador=='1'">NO</span>
                                            </b-col>
                                        </b-row>
                                    </template>

                                </b-table>
                            </b-col>
                        </b-row>
                    </div>
                </CCardBody>
                <CCardFooter>
                    <b-pagination v-model="currentPage" :total-rows="totalFilas" :per-page="porPagina" align="right" size="sm" class="my-0"></b-pagination>
                </CCardFooter>
            </CCard>
        </b-col>
    </b-row>
</div>
</template>

<script>
import firebase from 'firebase';
const dbVentas = firebase.firestore().collection('ventas');
const dbClientes = firebase.firestore().collection('clientes');
const dbServicios = firebase.firestore().collection('servicios');

export default {
    components: {

    },
    data() {
        return {
            totalFilas: 0,
            currentPage: 1,
            filter: null,
            filterOn: [],
            porPagina: 10,
            paginaOpciones: [10, 25, 50, 100, {
                value: 9999999,
                text: "Mostrar todos"
            }],

            campoOfertas: [{
                    key: "index",
                    label: "N°",
                    class: "text-center",
                },
                {
                    key: "cliente",
                    label: "Cliente",
                    class: "text-center",
                },
                {
                    key: "fechaVenta",
                    label: "Inicio contrato",
                    class: "text-center",
                },
                {
                    key: "fechaFinContrato",
                    label: "Finalización",
                    class: "text-center",
                },
                {
                    key: "total",
                    label: "Importe",
                    class: "text-center",
                },

                {
                    key: "acciones",
                    label: "",
                    class: "text-center"
                }
            ],
            listaOfertas: [],
        }
    },
    methods: {
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        onFiltered(filteredItems) {
            // Trigger pagination to update the number of buttons/pages due to filtering
            this.totalFilas = filteredItems.length
            this.currentPage = 1
        },
        validarFecha(param) {
            return moment(param.item.fechaFinContrato).isSameOrBefore(moment().format('YYYY-MM-DD'))
        },
        listarOfertas() {
            let me = this;
            dbVentas.where("activo", "==", 1).where("estado", "==", 2)
                .onSnapshot((querySnapshot) => {
                    me.listaOfertas = [];
                    querySnapshot.forEach((doc) => {
                        dbClientes.doc(doc.data().idCliente).get().then((docCliente) => {
                            let servicios = [];
                            for (const i in doc.data().listaServicios) {
                                dbServicios.doc(doc.data().listaServicios[i].idServicio)
                                    .onSnapshot((docServ) => {
                                        servicios.push({
                                            servicio: docServ.data().descripcion
                                        })
                                    });
                            }
                            me.listaOfertas.push({
                                idVenta: doc.id,
                                cliente: docCliente.data().razonSocial,
                                fechaVenta: doc.data().fechaVenta,
                                fechaInicioContrato: doc.data().fechaInicioContrato,
                                fechaFinContrato: doc.data().fechaFinContrato,
                                conColaborador: doc.data().conColaborador,
                                listaServicios: servicios,
                                total: doc.data().total,
                                estado: doc.data().estado,
                            });

                        }).catch((error) => {
                        });

                    });
                    me.listaOfertas.sort((a, b) => (a.fechaCreacion > b.fechaCreacion) ? -1 : ((b.fechaCreacion > a.fechaCreacion) ? 1 : 0))
                });
        },
        verDetalleOferta(param) {
            let me = this;
            me.$router.push({
                name: 'Gestión de oferta master',
                params: {
                    id: param.item.idVenta,
                }
            });
        },
        eliminarOferta(param) {
            let me = this
            me.$swal.fire({
                title: '¿Estas seguro de eliminar la oferta?',
                text: "¡No podrás revertir esto!",
                icon: 'warning',
                showCancelButton: false,
                confirmButtonColor: '#d33',
                confirmButtonText: 'Eliminar',
            }).then((result) => {
                if (result.isConfirmed) {
                    dbVentas.doc(param.item.idVenta).update({
                            estado: 1,
                        })
                        .then(() => {
                            me.swat('success', 'Eliminado satisfactoriamente')
                        })
                        .catch((error) => {
                            me.swat('error', 'Algo salió mal!')
                        });
                }
            })
        },
        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },
    },
    mounted() {
        let me = this;
        me.listarOfertas();
    }

}
</script>
